@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
/* --- MAIN STYLE ---*/
:root {
  --primary-color: rgb(4, 88, 243);
}

body {
  background: #f5f5f5;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

a {
  color: #444;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

section {
  padding: 5em 2em;
}

/* --- NAVIGATION STYLE ---*/
.nav-bar {
  background: white;
  padding: 1em;
}

.nav-bar .logo-nav {
  text-decoration: none;
  font-weight: bold;
  color: #000;
  font-size: 1.2em;
}

.nav-bar .logo-nav span {
  color: var(--primary-color);
}

.nav-bar nav {
  display: none;
}

.nav-bar .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-content: space-between;
}

.nav-bar .mobile-menu {
  cursor: pointer;
}

/* --- HERO STYLE ---*/
.hero {
  text-align: center;
}

.left-col .sub-head {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 1.2em;
  color: #6e6c6c;
}

.left-col h1 {
  text-transform: capitalize;
  font-size: 2.5em;
  font-weight: bolder;
  margin-top: 0.1em;
  line-height: 1.3em;
}

.primery-cta {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
  padding: 0.6em 1.3em;
  font-size: 1.4em;
  border-radius: 5em;
  font-weight: bold;
  display: inline-block;
}

.watch-video-cta {
  display: block;
  margin-top: 1em;
}

.watch-video-cta img {
  display: line-block;
  margin-top: -0.5em;
  margin-right: 0.5em;
  vertical-align: middle;
  width: 2.5em;
}

.hero-img {
  width: 80%;
  margin-top: 3em;
}

/* --- FEATURES STYLE ---*/
.features-section {
  background: #20272e;
  color: #fff;
}

.features-section ul {
  margin: 0;
  padding-left: 0.1em;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(19rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
}

.features-section li {
  font-size: 1.1em;
  margin-bottom: 1em;
  margin-left: 2em;
  position: relative;
}

.features-section li:before {
  content: "";
  position: absolute;
  left: -2em;
  width: 20px;
  height: 20px;
  background: url("./Img/bullet.svg");
  background-size: contain;
}

.features-section img {
  display: none;
}

/* --- TEST STYLE ---*/
.test-monials-section {
  background: var(--primary-color);
  color: #fff;
}

.test-monials-section img {
  width: 50%;
  height: 30%;
  border: 5px solid #0067d6b9;
  border-radius: 5em;
  margin-top: -4.5em;
  background-size: cover;
}

.test-monials-section li {
  background: #2083ee42;
  text-align: center;
  padding: 2em 1em;
  width: 80%;
  margin: 0 auto 5em auto;
  border-radius: 1em;
}

/* --- CONTACT STYLE ---*/
h2 {
  font-size: 2em;
}

label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

input,
textarea {
  width: 100%;
  padding: 0.8em;
  margin-bottom: 1em;
  border-radius: 0.3em;
  border: 1px solid gray;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

input[type="submit"] {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
  font-size: 1.3em;
  border-radius: 5em;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5em;
  padding: 0.8em 2em;
  cursor: pointer;
}

iframe {
  width: 100%;
  height: 300px;
}

nav.menu-btn {
  display: block;
}

nav {
  position: fixed;
  z-index: 999;
  width: 66%;
  right: 0;
  top: 0;
  background-color: #20272e;
  height: 50vh;
  padding: 1em;
}

nav ul.primary-nav {
  margin-top: 4em;
}

nav li a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 1em;
  text-align: right;
  -webkit-transition: font-weight 0.2s;
  transition: font-weight 0.2s;
}

nav li a:hover {
  font-weight: bold;
}

.mobile-menu-exit {
  display: block;
  float: right;
  margin: 0.5em;
  cursor: pointer;
}

/* --- 769px STYLE ---*/
@media only screen and (min-width: 768px) {
  .mobile-menu,
  .mobile-menu-exit {
    display: none;
  }
  .nav-bar .container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 180px auto;
        grid-template-columns: 180px auto;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
  .nav-bar nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background: none;
    position: unset;
    height: auto;
    width: 100%;
    padding: 0;
  }
  .nav-bar nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .nav-bar nav a {
    color: #000;
    padding: 0.1em 1em;
    font-size: 1em;
  }
  .nav-bar ul.primary-nav {
    margin: 0;
  }
  .nav-bar li.current a {
    font-weight: bold;
  }
  .nav-bar li.go-premium-cta a {
    color: var(--primary-color);
    border: 3px solid var(--primary-color);
    border-radius: 5em;
    font-weight: bold;
    margin-top: -0.2em;
  }
  .nav-bar li.go-premium-cta a:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
}

/* --- 1080px STYLE ---*/
@media only screen and (min-width: 1080px) {
  .container {
    width: 1050px;
    margin: 0 auto;
  }
  section {
    padding: 10em 4em;
  }
  .hero .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: left;
  }
  .left-col {
    margin: 4em 3em 0 5em;
  }
  .left-col h1 {
    font-size: 3em;
    width: 90%;
  }
  .hero-img {
    width: 30%;
    margin-right: 8em;
  }
  .hero-cta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .primery-cta {
    margin-right: 1em;
  }
  .features-section ul {
    display: block;
    margin-left: 5em;
  }
  .features-section ul li {
    font-size: 1.4em;
  }
  .features-section ul li::before {
    margin-top: -0.1em;
    width: 30px;
    height: 30px;
  }
  .features-section {
    position: relative;
  }
  .features-section img {
    display: block;
    position: absolute;
    right: 0;
    width: 350px;
    bottom: -2em;
  }
  .test-monials-section ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
  }
  .test-monials-section ul li {
    margin: 0 1em;
  }
  .contact-section {
    position: relative;
  }
  .contact-section .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .contact-section .contact-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 45%;
    height: 100%;
  }
  .contact-section iframe {
    height: 100%;
    margin-left: 5em;
  }
}

/* --- 1450px STYLE ---*/
@media only screen and (min-width: 1450px) {
  .features-section::before {
    content: "";
    position: absolute;
    width: 10%;
    height: 20em;
    background: var(--primary-color);
    left: 0;
    top: -4em;
  }
  .features-section::after {
    content: "";
    position: absolute;
    width: 200px;
    height: 20em;
    background: url("./Img/dots.svg") no-repeat;
    left: 4.5;
    top: 2em;
  }
}
